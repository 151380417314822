import * as React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import IframeResizer from 'iframe-resizer-react';

import { config } from '../../../config';
import AssetsContainer from '../containers/assets/Assets.container';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import EasyOnboardingDialog from './assets/easyOnboarding/EasyOnboardingDialog';
import { ConfigCatProvider, createConsoleLogger, DataGovernance, PollingMode } from 'configcat-react';
import { DEFAULT_LOCALE } from '../../../configuration/lang/lang';
import { MessageHandlerContainer } from '../containers/browsercommunication/MessageHandlerContainer';
import { getDisplayMessages, getLocale } from '../../../configuration/lang/langSlice';
import { isUserSessionExpired } from '../../../configuration/login/loginSlice';
import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from '../reducers/appSlice';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { getIdToken } from '../../../configuration/tokenHandling/tokenHandlingSlice';

export const App = () => {
    const dispatch = useAppDispatch();

    const homeRoute = config.homeRoute as string;
    const languageData = useAppSelector(getDisplayMessages);
    const showSessionExpired = useAppSelector(
        (state) => isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state)
    );
    const userLocale = useAppSelector(getLocale);
    const idToken = useAppSelector(getIdToken) ?? '';

    const navItems = [
        {
            key: 'assets',
            route: (
                <NavLink to="/assets">
                    <FormattedMessage id="assets.sublink.assets" />
                </NavLink>
            ),
        },
        {
            key: 'drivers',
            route: (
                <a href={config.external.driverAdministration as string}>
                    <FormattedMessage id="assets.sublink.drivers" />
                </a>
            ),
        },
        {
            key: 'tags',
            route: (
                <a href={config.external.tagAdministration as string}>
                    <FormattedMessage id="assets.sublink.tags" />
                </a>
            ),
        },
    ];

    const appTitle = <FormattedMessage id={'assets.moduleName'} />;
    const userMenu = (
        <DefaultUserMenu
            environment={import.meta.env.PROD ? 'production' : 'development'}
            postLogout={{ redirectUri: config.login.postLogoutRedirectUri!, idTokenHint: idToken }}
        />
    );
    const menuUrl = config.backend.MENU_SERVICE as string;
    const notifications = <RioNotifications />;
    const appNavigator = <IframeResizer className="iFrameResizer" src={menuUrl} />;

    const homeLink = <a href={homeRoute}>{''}</a>;
    const logger = createConsoleLogger(config.configCat.logLevel); // Set the log level to INFO to track how your feature flags were evaluated. When moving to production, you can remove this line to avoid too detailed logging.

    return (
        <IntlProvider
            defaultLocale={DEFAULT_LOCALE}
            key={userLocale}
            locale={userLocale ?? DEFAULT_LOCALE}
            messages={languageData!}
        >
            <ConfigCatProvider
                sdkKey={config.configCat.apiKey}
                pollingMode={PollingMode.LazyLoad}
                options={{
                    cacheTimeToLiveSeconds: config.configCat.pollingFrequencyInSeconds,
                    logger,
                    dataGovernance: DataGovernance.EuOnly,
                }}
            >
                <ApplicationLayout>
                    <ApplicationLayout.Header>
                        <ApplicationHeader
                            label={appTitle}
                            appNavigator={appNavigator}
                            homeRoute={homeLink}
                            navItems={navItems}
                            actionBarItems={[notifications, userMenu]}
                        />
                    </ApplicationLayout.Header>
                    <NotificationsContainer />
                    <SessionExpiredDialog
                        locale={userLocale ?? DEFAULT_LOCALE}
                        onClose={() => dispatch(hideSessionExpiredDialog())}
                        show={showSessionExpired}
                    />
                    <EasyOnboardingDialog />
                    <MessageHandlerContainer />
                    <AssetsContainer />
                </ApplicationLayout>
            </ConfigCatProvider>
        </IntlProvider>
    );
};
