import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { extractLanguage } from './lang/lang';
import { getLocale } from './lang/langSlice';
import { configureFetchDisplayMessages } from './lang/services';
import { configureMockUserManager, configureUserManager, createUserManager, OAuthConfig } from './login/login';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import { store } from './setup/store';
import { accessToken } from './tokenHandling/accessToken';
import { accessTokenStored, idTokenStored } from './tokenHandling/tokenHandlingSlice';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import { config } from '../config';
import { reportErrorToSentry } from './setup/sentry';
import { SessionRenewedResult } from './types';
import { User } from 'oidc-client-ts';

export const main = (renderApp: (user: User | null | void) => void) => {
    const fetchLanguageData = configureFetchDisplayMessages(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig: OAuthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());

            // You will need to get the user language by yourself then
            // you may fetch the suitable messages from the CDN. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            void fetchLanguageData(result.locale);
        },
    };

    const isAllowedToMockAuth = import.meta.env.DEV;
    // enable mocking of authentication in production
    const capabilityMonitoringAccessToken = sessionStorage && sessionStorage.getItem('test_access_token');
    const testIdToken = sessionStorage && sessionStorage.getItem('test_id_token');
    const userManager =
        (isAllowedToMockAuth && config.login.mockAuthorization) || capabilityMonitoringAccessToken
            ? configureMockUserManager(oauthConfig, { capabilityMonitoringAccessToken, idToken: testIdToken, store })
            : configureUserManager(oauthConfig, createUserManager());

    document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, userManager.signinSilent.bind(userManager) as EventListener);
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, userManager.signinSilent.bind(userManager) as EventListener);

    attemptInitialSignIn(userManager)
        .then(renderApp)
        .catch((error) => {
            trace('could not start application', error);
            reportErrorToSentry(error);
        });
};

// expose store when run in Cypress
// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((window as any).Cypress) {
    window.store = store;
}
