import join from 'lodash/fp/join';
import { mapUserProfile } from './userProfile';
import { config } from '../../config';
import { InMemoryWebStorage, User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { userProfileObtained } from './loginSlice';
import { jwtDecode } from 'jwt-decode';
import { SessionRenewedResult } from '../types';
import { Store } from '@reduxjs/toolkit';

export const TOKEN_SUBJECT = 'prod-rio-users:mock-user';
const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

// used in cypress
const CHANGE_USER_PROFILE_BROWSER_EVENT_TYPE = 'mock:change-user-profile-browser-event';

export interface OAuthConfig {
    onSessionExpired: () => void;
    onSessionRenewed: (arg: SessionRenewedResult) => void;
}

interface Tokens {
    capabilityMonitoringAccessToken: string | null;
    idToken: string | null;
}

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.signinSilent().catch((error) => {
        if (error.message === 'login_required') {
            oauthConfig.onSessionExpired();
        } else {
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
        }
    });
};

const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
    accessToken: result.access_token,
    idToken: result.id_token ?? null,
    locale: result.profile?.locale ?? 'en-GB',
    profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
    const redirectUri = config.login.redirectUri;
    const silentRedirectUri = config.login.silentRedirectUri;

    const settings = {
        authority: `${config.login.authority}`,
        client_id: `${config.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: 'code',
        scope: join(' ', config.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        monitorSession: true,
        staleStateAgeInSeconds: 600,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    };

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.events.addUserLoaded((user) => {
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
    });

    userManager.events.addUserUnloaded(() => {
        oauthConfig.onSessionExpired();
    });

    userManager.events.addSilentRenewError(() => {
        retrySigninSilent(oauthConfig, userManager);
    });

    userManager.events.addUserSignedOut(() => {
        oauthConfig.onSessionExpired();
    });

    return userManager;
};

async function getMockToken() {
    const tokenEndpoint = `${config.backend.assetAdmin}/token`;
    const localTestFleet = config.login.mockFleetId;
    const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            account: localTestFleet,
            scope: join(' ', config.login.oauthScope),
            sub: TOKEN_SUBJECT,
            azp: '6ea5388f-bb13-49ab-9a4e-fc45e0f326f2',
        }),
    });

    if (response.status === 200) {
        const token = await response.text();

        return token;
    } else {
        return 'valid-mocked-oauth-bogus-token';
    }
}

async function setMockPermissions() {
    const permissionEndpoint = `${config.backend.assetAdmin}/permissions/${TOKEN_SUBJECT}`;
    const localTestFleet = config.login.mockFleetId;
    const accountBasedPermissions = ['ASSET_READ', 'ASSET_WRITE'];
    const permissions: { ACCOUNT_BASED: Record<string, any> } = {
        ACCOUNT_BASED: {},
    };
    permissions.ACCOUNT_BASED[localTestFleet!] = accountBasedPermissions;
    const response = await fetch(permissionEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(permissions),
    });

    if (response.status !== 201) {
        console.warn('Unable to set mock permissions');
    }
}

export const configureMockUserManager = (
    oauthConfig: OAuthConfig,
    { capabilityMonitoringAccessToken, idToken, store }: Tokens & { store: Store }
): UserManager => {
    console.warn('[feature/login/oidc-session] Using mocked authorization due to config setting');

    const jwtPayload = idToken && jwtDecode(idToken);
    const profile = jwtPayload
        ? jwtPayload
        : {
              account: '00000000-0000-0000-0000-000000000000',
              azp: 'test-client',
              email: 'test@example.com',
              family_name: 'Client',
              given_name: 'Test',
              locale: config.login.mockLocale,
              name: 'Test Client',
              tenant: 'rio-eu.test',
              sub: 'prod-rio-users:mock-user',
          };

    // allow setting the tenant dynamically during cypress tests
    document.addEventListener(
        CHANGE_USER_PROFILE_BROWSER_EVENT_TYPE,
        import.meta.env.DEV
            ? (((event: CustomEvent<{ tenant: string; accountId: string | undefined }>) => {
                  const tenant = event.detail.tenant;
                  const accountId = event.detail.accountId;
                  if (tenant && accountId) {
                      console.log(`[Change of User Profile Triggered] New tenant and account id:`, event.detail);
                      store.dispatch(userProfileObtained({ ...profile, tenant: tenant, account: accountId }));
                  } else if (tenant) {
                      console.log(`[Change of User Profile Triggered] New tenant:`, event.detail);
                      store.dispatch(userProfileObtained({ ...profile, tenant: tenant }));
                  } else if (accountId) {
                      console.log(`[Change of User Profile Triggered] New account id:`, event.detail);
                      store.dispatch(userProfileObtained({ ...profile, account: accountId }));
                  }
              }) as EventListener)
            : () => {}
    );
    window.switchTenant = (tenant) => {
        if (import.meta.env.DEV)
            document.dispatchEvent(new CustomEvent(CHANGE_USER_PROFILE_BROWSER_EVENT_TYPE, { detail: { tenant } }));
    };

    let signinSilent: () => Promise<User | null>;

    if (capabilityMonitoringAccessToken) {
        signinSilent = () => {
            const userSettings = {
                access_token: capabilityMonitoringAccessToken || 'valid-mocked-oauth-bogus-token',
                profile: {
                    iss: (jwtPayload && jwtPayload.iss) || 'Issuer Identifier',
                    sub: (jwtPayload && jwtPayload.sub) || 'prod-rio-users:mock-user-id',
                    aud: (jwtPayload && jwtPayload.aud) || 'Audience(s): client_id',
                    exp: (jwtPayload && jwtPayload.exp) || 10,
                    iat: (jwtPayload && jwtPayload.iat) || 5,
                    account: 'mockaccount',
                    azp: 'test-client',
                    email: 'test@example.com',
                    family_name: 'Client',
                    given_name: 'Test',
                    name: 'Test Client',
                    username: 'preferred_username',
                    locale: config.login.mockLocale,
                    tenant: config.login.mockTenant,
                    rawValue: idToken || 'fake-id-token',
                },
                id_token: idToken || 'fake-id-token',
                session_state: 'session_state',
                refresh_token: 'refresh_token',
                token_type: 'token_type',
                scope: 'scope',
                expires_at: 100000,
                state: 'state',
            };
            const mockUser = new User(userSettings);
            oauthConfig.onSessionRenewed(adaptPublishedInfo(mockUser));
            return Promise.resolve(mockUser);
        };
    } else {
        signinSilent = async () => {
            const accessToken = await getMockToken();
            await setMockPermissions();

            const mockSettings = {
                id_token: 'id_token',
                session_state: 'session_state',
                access_token: accessToken,
                refresh_token: 'refresh_token',
                token_type: 'token_type',
                scope: join(' ', config.login.oauthScope),
                profile: {
                    iss: (jwtPayload && jwtPayload.iss) || 'Issuer Identifier',
                    sub: (jwtPayload && jwtPayload.sub) || 'prod-rio-users:mock-user-id',
                    aud: (jwtPayload && jwtPayload.aud) || 'Audience(s): client_id',
                    exp: (jwtPayload && jwtPayload.exp) || 10,
                    iat: (jwtPayload && jwtPayload.iat) || 5,
                    account: 'mockaccount',
                    azp: 'test-client',
                    email: 'test@example.com',
                    family_name: 'Client',
                    given_name: 'Test',
                    name: 'Test Client',
                    username: 'preferred_username',
                    locale: config.login.mockLocale,
                    tenant: config.login.mockTenant,
                    rawValue: idToken || 'fake-id-token',
                },
                expires_at: 0,
                state: '',
            };

            const user = new User(mockSettings);
            oauthConfig.onSessionRenewed(adaptPublishedInfo(user));

            return user;
        };
    }

    const clearStaleState = () => {
        console.info('[configuration/login/oidc-session] Stale state cleared');
        return Promise.resolve();
    };

    return { signinSilent, clearStaleState } as UserManager;
};
