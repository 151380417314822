import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { accessToken } from './accessToken';
import { AccessToken } from '../types';
import { RootState } from '../setup/store';

interface TokenHandlingState {
    accessToken: AccessToken;
    idToken: string | null;
}

const initialState: TokenHandlingState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
};

const tokenHandlingSlice = createSlice({
    name: 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored: (state, action: PayloadAction<AccessToken>) => {
            state.accessToken = action.payload;
        },
        idTokenStored: (state, action: PayloadAction<string | null>) => {
            state.idToken = action.payload;
        },
    },
});

export const { accessTokenStored, idTokenStored } = tokenHandlingSlice.actions;

export const getAccessToken = (state: RootState) => state.tokenHandling.accessToken;

export const getIdToken = (state: RootState) => state.tokenHandling.idToken;

export default tokenHandlingSlice.reducer;
