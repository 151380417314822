import { FormStep, FormStepNavigation } from './EasyOnboardingDialog';
import { FormattedMessage } from 'react-intl';
import {
    GAEventName,
    GAEventTrigger,
    GAFlowName,
    useGoogleAnalytics,
} from '../../../utils/googleAnalytics4AssetOnboardingFlow';
import { BrandTile, TelematicsTile } from './configuration/types';

interface DialogFooterProps {
    formStep: FormStep;
    handleFormStepNavigation: (goTo: FormStepNavigation) => void;
    setSelectedTelematics: (value: TelematicsTile | undefined) => void;
    selectedTelematics: TelematicsTile | undefined;
    setSelectedBrand: (value: BrandTile | undefined) => void;
    selectedBrand: BrandTile | undefined;
    selectedAssetId: string | undefined;
    disableFooter?: boolean;
}
export const DialogFooter = (props: DialogFooterProps) => {
    const showPrevButton =
        props.formStep === FormStep.selectBrand ||
        (props.formStep === FormStep.selectTelematics && props.selectedAssetId === undefined);
    const showSkipBrandSelectionButton = props.formStep === FormStep.selectBrand;
    const showNextButton = props.formStep === FormStep.selectTelematics;
    const { dataLayerPush } = useGoogleAnalytics();
    const footerContent = (
        <div className="display-flex justify-content-between padding-20 bg-white border border-top-only border-color-lighter rounded-bottom">
            {showPrevButton ? (
                <button
                    className="btn btn-default"
                    type="button"
                    onClick={() => {
                        props.handleFormStepNavigation(FormStepNavigation.prev);
                    }}
                >
                    <span className={'rioglyph rioglyph-chevron-left'} />
                    <FormattedMessage id="assets.assets.create.back" />
                </button>
            ) : (
                <span />
            )}
            {showSkipBrandSelectionButton && (
                <button
                    className="btn btn-default"
                    type="button"
                    onClick={() => {
                        props.setSelectedBrand(undefined);
                        props.handleFormStepNavigation(FormStepNavigation.next);
                    }}
                    data-cy="skip-brand-selection-button"
                >
                    <FormattedMessage id="assets.assets.create-new-dialog.directly-to-telematics" />
                </button>
            )}
            {showNextButton && (
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                        props.handleFormStepNavigation(FormStepNavigation.next);
                        dataLayerPush({
                            trigger: GAEventTrigger.click,
                            event: GAEventName.selectTelematics,
                            element_name: getLastSegment(props.selectedTelematics?.displayName),
                            flow_name: GAFlowName.easyOnboardingFlow,
                        });
                    }}
                >
                    <div className="padding-10">
                        <FormattedMessage id="assets.assets.create-new-dialog.next" />
                    </div>
                    <span className={'rioglyph rioglyph-chevron-right'} />
                </button>
            )}
        </div>
    );
    return !props.disableFooter && (showPrevButton || showSkipBrandSelectionButton || showNextButton)
        ? footerContent
        : null;
};

function getLastSegment(input: string | undefined): string {
    if (input === undefined) {
        return '';
    }
    const segments = input.split('.');
    return segments[segments.length - 1];
}
